import { useContext, useEffect, useState } from "react";
import { CalendarEventList } from "./CalendarEventList";
import { AppContext } from "../../App.state";
import { inOffCanvasHOC } from "../../common/components/offcanvas/InOffCanvasHOC";
import { CalendarEventEditor } from "./CalendarEventEditor";
import { CalendarEvent, emptyCalendarEvent } from "./CalenderEvent.model";
import { useBackendApi } from "../../common/backend/backendApi";
import CalendarEventViewer from "./CalendarEventViewer";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { FloatingActionButton } from "../../common/components/buttons/FloatingActionButton";

enum BarType {
  VIEWER = "VIEWER",
  EDITOR = "EDITOR",
  NONE = "",
}

export function CalendarAdministrationPage() {
  const { eventId } = useParams();
  const appState = useContext(AppContext).state;
  const calendarEvents = useBackendApi().calendarEvents;
  const [activeView, setActiveView] = useState({
    barType: eventId ? BarType.VIEWER : BarType.NONE,
    selectedEventId: eventId ?? "",
  });

  useEffect(() => {
    calendarEvents
      .list()
      .then(() => console.debug("Loaded calendar events"))
      .catch((error) => {
        console.error("Failed to load calendar events", error);
      });
    calendarEvents
      .listOld()
      .then(() => {
        console.debug("Loaded old calendar events");
      })
      .catch((error) => {
        console.error("Failed to load old calendar events", error);
      });
  }, [calendarEvents]);

  if (appState.calendarEvents.loading ?? true) {
    return <div>Laddar händelser...</div>;
  }

  const saveEvent = (calendarEvent: CalendarEvent) => {
    const createOrUpdate = isEmpty(calendarEvent.uuid) ? calendarEvents.add : calendarEvents.update;

    createOrUpdate(calendarEvent)
      .then(reloadList)
      .catch(() => console.warn("Unable to save calendar event"));
  };

  const deleteEvent = () => calendarEvents.delete(activeView.selectedEventId).then(reloadList);

  const resetActiveView = () => setActiveView({ barType: BarType.NONE, selectedEventId: "" });

  const reloadList = () =>
    calendarEvents.list(true).then(() => {
      resetActiveView();
      console.debug("Reloaded calendar events");
    });

  function getCalendarEvent(selectedCalEventId: string): CalendarEvent | undefined {
    if (isEmpty(selectedCalEventId)) return emptyCalendarEvent;
    const event = appState.calendarEvents.value?.find((event) => event.uuid === selectedCalEventId);
    if (!event) {
      if (appState.oldCalendarEvents.value) {
        return appState.oldCalendarEvents.value?.find((event) => event.uuid === selectedCalEventId);
      }
    }
    return event;
  }

  return (
    <main id="calendarAdminView" className="container p-2">
      <CalendarEventList
        events={appState.calendarEvents}
        oldEvents={appState.oldCalendarEvents}
        onViewEvent={(eventId: string) =>
          setActiveView({
            barType: BarType.VIEWER,
            selectedEventId: eventId,
          })
        }
      />
      <FloatingActionButton
        label="Lägg till"
        bottom={70}
        onClick={() => setActiveView({ barType: BarType.EDITOR, selectedEventId: "" })}
      />

      <CalendarEventViewerBar
        show={activeView.barType === BarType.VIEWER}
        onHide={resetActiveView}
        onEdit={(eventId: string) => {
          setActiveView({
            barType: BarType.EDITOR,
            selectedEventId: eventId,
          });
        }}
        calendarEvent={getCalendarEvent(activeView.selectedEventId)}
      />

      <CalendarEventEditorBar
        show={activeView.barType === BarType.EDITOR}
        onHide={resetActiveView}
        onSave={saveEvent}
        onCancel={resetActiveView}
        onDelete={deleteEvent}
        calendarEvent={getCalendarEvent(activeView.selectedEventId)}
      />
    </main>
  );
}

const CalendarEventEditorBar = inOffCanvasHOC(CalendarEventEditor, "end", "Kalenderhändelse");
const CalendarEventViewerBar = inOffCanvasHOC(CalendarEventViewer, "end", "Kalenderhändelse");
